import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

const LoadingWithImage = ({ imageUrl, loadingText = "Loading..." }) => {
  return (
    <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
      {/* Image displayed in background */}
      <Box sx={{ position: 'absolute', top: '20%', zIndex: -1 }}>
        <img src={imageUrl} alt="Loading Image" style={{ width: '150px', height: '150px', objectFit: 'contain' }} />
      </Box>
      
      {/* Loading Bar */}
      <Typography sx={{ color: 'white', marginBottom: '20px' }} variant="h6">
        {loadingText}
      </Typography>
      
      {/* Progress Bar */}
      <Box sx={{ width: '80%', marginTop: '20px' }}>
        <LinearProgress sx={{ height: 10, borderRadius: '5px', backgroundColor: '#f0f0f0' }} />
      </Box>
      
      {/* Optional Text: Show Progress Percentage */}
      <Typography sx={{ color: 'white', marginTop: '10px' }} variant="body1">
        0% Loaded
      </Typography>
    </Box>
  );
};

export default LoadingWithImage;
