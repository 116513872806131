import React, { lazy, Fragment, useState, Suspense } from "react";
import { Outlet } from "react-router-dom";

const Header = lazy(() => import("../components/header/Header"));
const Navigation = lazy(() => import("../components/navigation/Navigation"));
const TawkToChat = lazy(() => import("../components/TawkToChat/TawkToChat"));
const SideBar = lazy(() => import("../components/sideBar/SideBar"));
const DrawerCart = lazy(() => import("../components/drawer/Drawer"));
const Login = lazy(() => import("../components/login/Login"));
const Register = lazy(() => import("../components/register/Register"));
const Footer = lazy(() => import("../components/footer/Footer"));


const AuthLayout = ({ isOpenRegister, setIsOpenRegister }) => {
  return (
    <div className="auth-layout">
      <Fragment>
        <Suspense fallback={<div>Loading Header...</div>}>
          <Header />
        </Suspense>
        <Suspense fallback={<div>Loading Navigation...</div>}>
          <Navigation />
        </Suspense>
        <Suspense fallback={<div>Loading Chat...</div>}>
          <TawkToChat />
        </Suspense>

        <Outlet />

        <Suspense fallback={<div>Loading Extras...</div>}>
          <SideBar />
          <DrawerCart />
          <Login setIsOpenRegister={setIsOpenRegister} />
          <Register isOpen={isOpenRegister} setIsOpenRegister={setIsOpenRegister} />
        </Suspense>

        <Suspense fallback={<div>Loading Footer...</div>}>
          <Footer />
        </Suspense>
        {/* No Footer in AuthLayout */}
      </Fragment>
    </div>
  );
};

export default AuthLayout;
