import React, { lazy, Fragment, Suspense } from "react";
import { Outlet } from "react-router-dom";

// Lazy-loaded components for Default Layout
const Header = lazy(() => import("../components/header/Header"));
const Footer = lazy(() => import("../components/footer/Footer"));
const MobileApp = lazy(() => import("../components/mobileApp/MobileApp"));
const Navigation = lazy(() => import("../components/navigation/Navigation"));
const TawkToChat = lazy(() => import("../components/TawkToChat/TawkToChat"));
const FooterHeader = lazy(() => import("../components/footerHeader/defaultFooter/FooterHeader"));
const SideBar = lazy(() => import("../components/sideBar/SideBar"));
const DrawerCart = lazy(() => import("../components/drawer/Drawer"));
const Login = lazy(() => import("../components/login/Login"));
const Register = lazy(() => import("../components/register/Register"));

const DefaultLayout = ({ isOpenRegister, setIsOpenRegister }) => {
  return (
    <Fragment>
      <Suspense fallback={<div>Loading Header...</div>}>
        <Header />
      </Suspense>
      <Suspense fallback={<div>Loading Navigation...</div>}>
        <Navigation />
      </Suspense>
      <Suspense fallback={<div>Loading Chat...</div>}>
        <TawkToChat />
      </Suspense>

      {/* Main Content Area */}
      <main>
        <Outlet />
      </main>

      {/* Only render FooterHeader and Footer once, after the content */}
      <Suspense fallback={<div>Loading Footer...</div>}>
        <FooterHeader />
      </Suspense>

      {/* Other components */}
      <Suspense fallback={<div>Loading Extras...</div>}>
        <SideBar />
        <DrawerCart />
        <Login setIsOpenRegister={setIsOpenRegister} />
        <Register isOpen={isOpenRegister} setIsOpenRegister={setIsOpenRegister} />
      </Suspense>

      <Suspense fallback={<div>Loading Footer...</div>}>
        <MobileApp />
        <Footer />
      </Suspense>

    </Fragment>
  );
};


export default DefaultLayout;
