import {
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,

    ADD_SHIPPING_ADDRESS_REQUEST,
    ADD_SHIPPING_ADDRESS_SUCCESS,
    ADD_SHIPPING_ADDRESS_FAIL,

    // UPDATE_PROFILE,
    CHANGE_PASSWORD,
} from "../../constants/categoryConstants";
 


/* REDUCER Contact Us IN USER REGISTRATION IN RegisterScreen COMPONENT */
export const userProfileFormReducers = (state = {}, action) => {
    switch (action.type) {
      case UPDATE_PROFILE_REQUEST:
        return {
          loading: true,
        };
  
      case UPDATE_PROFILE_SUCCESS:
        return {
          loading: false,
          userProfileInfo: action.payload,
        };
  
      case UPDATE_PROFILE_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  

  
      default:
        return state;
    }
  };





  const initialStateAddress = {
    loading: false,
    error: null,
    successMessage: null,
  };
  
  
  export const shippingAddressReducer = (state = initialStateAddress, action) => {
    switch (action.type) {
      case ADD_SHIPPING_ADDRESS_REQUEST:
        return { ...state, loading: true };
      case ADD_SHIPPING_ADDRESS_SUCCESS:
        return { ...state, loading: false, successMessage: action.payload };
      case ADD_SHIPPING_ADDRESS_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };


  
  const initialState = {
    shippingAddress: null,
    userProfile: null,
    passwordChangeMessage: "",
  };
  
  export const userReducer = (state = initialState, action) => {
    switch (action.type) {
      // case ADD_SHIPPING_ADDRESS:
      //   return { ...state, shippingAddress: action.payload };
      // case UPDATE_PROFILE:
      //   return { ...state, userProfile: action.payload };
      case CHANGE_PASSWORD:
        return { ...state, passwordChangeMessage: action.payload.message };
      default:
        return state;
    }
  };