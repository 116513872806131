import { BLOG_LIST_REQUEST,BLOG_LIST_SUCCESS,BLOG_LIST_FAIL,   
    BLOG_DETAILS_REQUEST,BLOG_DETAILS_SUCCESS, BLOG_DETAILS_FAIL 
} from '../../constants/categoryConstants'
    
export const blogListReducers =(state={blog:[]},action)=>{
    switch(action.type){
        case BLOG_LIST_REQUEST:
            return {laoding:true,blog:[]}
        case BLOG_LIST_SUCCESS:
            return {laoding:false,blog:action.payload}
        case BLOG_LIST_FAIL:
            return {laoding:false,error: action.payload}

        default:
            return state
    }
}




export const blogDetailsReducers =(state={singlebBlogDetails:[]},action)=>{
    switch(action.type){
        case BLOG_DETAILS_REQUEST:
            return {laoding:true,singlebBlogDetails:[]}
        case BLOG_DETAILS_SUCCESS:
            return {laoding:false,singlebBlogDetails:action.payload}
        case BLOG_DETAILS_FAIL:
            return {laoding:false,error: action.payload}
        default:
            return state
    }
}



// export const blogDetailsReducers = (state={ blog:{reviews:[]} },action) =>{
//     switch(action.type){
//         case BLOG_DETAILS_REQUEST:
//             return {loading:true,...state}
//         case BLOG_DETAILS_SUCCESS:
//             return {loading:false,blog:action.payload}
//         case BLOG_DETAILS_FAIL:
//             return {loading:false, error: action.payload }

//         default:
//             return state
//     }

// }