import { configureStore } from '@reduxjs/toolkit'
import { cartReducer } from './reducers/cartSlice'
import { isOpenReducer } from './reducers/isOpenSlice'
import { searchReducer } from './reducers/searchSlice'
import { shoppingCardReducer } from './reducers/shoppingCardSlice'
import { sidebarReducer } from './reducers/sidebarSlice'
import {categoryListReducers,categoryDetailsReducers} from './ownReducers/categoryReducers';
import { productsBycategoryDetailsReducers,productsBycategoryListReducers, productListReducers } from './ownReducers/productByCategoryReducers'
import { locationListReducers,locationDetailsReducers } from './ownReducers/locationReducers'
import { blogDetailsReducers, blogListReducers } from './ownReducers/blogReducers'
import { singleProductDetailsReducers, singleProductListReducers } from './ownReducers/singleProductReducers'
import { sliderDetailsReducers, sliderListReducers } from './ownReducers/sliderReducers'
import { couponDetailsReducers, couponListReducers } from './ownReducers/couponReducers'
import { reviewDetailsReducers, reviewListReducers } from './ownReducers/reviewReducers'
import { contactusFormReducers } from './ownReducers/contactusReducers'
import { checkOutFormReducers, checkOutListReducers } from './ownReducers/checkOutReducers'


import { shippingAddressReducer, userProfileFormReducers } from './ownReducers/updateProfileReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  otpRequestReducer,
  otpVerifyReducer, 
  changePasswordReducer
} from './ownReducers/userReducers';
import { CategoryLocationMapingListReducers } from './ownReducers/categoryLocationMapingReducers'
import { privacyPolicyDetailsReducers, privacyPolicyListReducers } from './ownReducers/privacyPolicyReducers'
import { FaqsQADetailsReducers, FaqsQAListReducers } from './ownReducers/faqsReducers'
import { orderPlaceFormReducers, orderPlaceListReducers,singleOrderPlaceListReducers } from './ownReducers/orderPlaceReducers'
import { ourTeamDetailsReducers, ourTeamListReducers } from './ownReducers/ourTeamReducers'
import { languageReducer } from './ownReducers/languageReducer'





export const store = configureStore({
  reducer: {
    language: languageReducer,

    // Location
    blogList:blogListReducers,
    blogDetails:blogDetailsReducers,
  
    // Location
    locationList:locationListReducers,
    locationDetails:locationDetailsReducers,

    // OurTeam
    ourTeamList: ourTeamListReducers,
    ourTeamDetails: ourTeamDetailsReducers,


    // Pdocut By Category
    productList: productListReducers,
    productsBycategoryList:productsBycategoryListReducers,
    productsBycategoryDetails:productsBycategoryDetailsReducers,
    // categoryList
    categoryList:categoryListReducers,
    categoryDetails:categoryDetailsReducers,
    

    // Particular Products
    singleProductList:singleProductListReducers,
    singleProductDetails:singleProductDetailsReducers,

    // slider List
    sliderList :sliderListReducers,
    sliderDetails:sliderDetailsReducers,

    // slider List
    couponList :couponListReducers,
    couponDetails : couponDetailsReducers,

    // Review List
    reviewList :reviewListReducers,
    reviewDetails : reviewDetailsReducers,


    // ContactUs Post
    contactusForm : contactusFormReducers,

    // CheckOut Post
    checkOutForm : checkOutFormReducers,
    checkOutList : checkOutListReducers,

    
    // Change PasswordForm Post
    shippingAddress: shippingAddressReducer,


    // User ProfileForm Post
    userProfileForm : userProfileFormReducers,



    // Order Place 
    orderPlaceForm : orderPlaceFormReducers,
    orderPlaceList: orderPlaceListReducers,
    singleOrderPlaceList: singleOrderPlaceListReducers,




    // ContactUs Post
    CategoryLocationMapingList : CategoryLocationMapingListReducers,

  
    // ContactUs Post
    privacyPolicyList : privacyPolicyListReducers,
    privacyPolicyDetails : privacyPolicyDetailsReducers,

    // ContactUs Post
    FaqsQAList : FaqsQAListReducers,
    FaqsQADetails : FaqsQADetailsReducers,





    userLogin: userLoginReducer,
    otpRequest: otpRequestReducer,
    otpVerify: otpVerifyReducer,
    
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfle: userUpdateProfileReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,

    changePassword:changePasswordReducer,


    
    

    shoppingCard:shoppingCardReducer,
    sidebar:sidebarReducer,
    cart:cartReducer,
    search:searchReducer,
    isOpen:isOpenReducer
  },
  devTools:true,
})



/* PULLING DATA OUT OF LOCAL STORAGE AND LOAD IT INTO INITIAL STATE */
const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

/* INITIAL STATE */
const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};
