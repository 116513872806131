// App.js
import "./App.css";
import { Routes, Route } from "react-router-dom";
import React, { lazy, Fragment, useState, Suspense, useEffect } from "react";
import DefaultLayout from "./layout/DefaultLayout";
import BlankLayout from "./layout/BlankLayout";
import AuthLayout from "./layout/AuthLayout";
import { useSelector } from "react-redux"; // Importing Redux hooks
import ProductList from "./components/productList/ProductList"; // Importing ProductList component
import LoadingWithImage from "./components/LoadingWithImage/LoadingWithImage";
// import logo from "./assets/img/favicon.png";
import logo from "./assets/Company/CompanyLodingimage.png";


// Lazy-loaded components for main routes
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Blog = lazy(() => import("./pages/Blog"));
const PortFolioPage = lazy(() => import("./components/portFolioPage/PortFolioPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const SignupPage = lazy(() => import("./pages/SignupPage"));
const Page404 = lazy(() => import("./components/error/404/404Page"));

// Other imports for routes...
const OurTeam = lazy(() => import("./pages/OurTeam"));
const Faqs = lazy(() => import("./pages/Faqs"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Service = lazy(() => import("./pages/Service"));
const Offers = lazy(() => import("./pages/Offers"));
const GalleryDisplay = lazy(() => import("./pages/Gallery"));
const TermsConditions = lazy(() => import("./pages/TermsConditions"));
const Product = lazy(() => import("./components/product/Product"));
const ReferEarn = lazy(() => import("./pages/ReferEarn"));
const OurLocation = lazy(() => import("./pages/OurLocation"));
const ProductByCategory = lazy(() => import("./pages/ProductByCategory"));
const Checkout = lazy(() => import("./components/checkout/Checkout"));
const Search = lazy(() => import("./components/search/Search"));
const OrderOld = lazy(() => import("./components/order/OrderOld"));
const Order = lazy(() => import("./components/order/Order"));

const User = lazy(() => import("./components/user/User"));
const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
const MyOrders = lazy(() => import("./components/myOrders/MyOrders"));
const MyAccount = lazy(() => import("./components/myAccount/MyAccount"));
const UpdateProfile = lazy(() => import("./components/updateProfile/UpdateProfile"));
const AddShippingAddress = lazy(() => import("./components/addShippingAddress/AddShippingAddress"));
const ChangePassword = lazy(() => import("./components/changePassword/ChangePassword"));
const BlogPost = lazy(() => import("./pages/BlogPost"));

function App() {
  let [isOpenRegister, setIsOpenRegister] = useState(false);

  const productList = useSelector((state) => state.productList || {});
  const { products = {} } = productList;

  useEffect(() => {
    const updateLocalStorage = () => {
      if (products?.result?.length > 0) {
        localStorage.setItem("productListArray", JSON.stringify(products.result));
        localStorage.setItem("lastUpdateTime", Date.now());
      }
    };
    const checkInactivity = () => {
      const lastUpdateTime = parseInt(localStorage.getItem("lastUpdateTime"), 10);
      const currentTime = Date.now();
      const fifteenMinutes = 900000;
      // If last update was more than 15 minutes ago, update localStorage
      if (!lastUpdateTime || currentTime - lastUpdateTime > fifteenMinutes) {
        updateLocalStorage();
      }
    };
    // Check for inactivity on mount
    checkInactivity();
    // Update immediately on mount
    updateLocalStorage();
    // Set up interval to update every 15 minutes
    const intervalId = setInterval(updateLocalStorage, 900000);
    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [products]);



  return (
    <Fragment>
      <Suspense fallback={<LoadingWithImage imageUrl={logo} loadingText="Loading ..." />}>

        <Routes>
          {/* Default Layout for main site pages */}
          <Route element={<DefaultLayout isOpenRegister={isOpenRegister} setIsOpenRegister={setIsOpenRegister} />}>
            <Route path="/" element={<Home />} />
            <Route path="about-us" element={<About />} />
            <Route path="contact-us" element={<Contact />} />
            <Route path="our-team" element={<OurTeam />} />
            <Route path="faqs" element={<Faqs />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="service" element={<Service />} />
            <Route path="gallery" element={<GalleryDisplay />} />
            <Route path="terms-conditions" element={<TermsConditions />} />
            <Route path="offers" element={<Offers />} />
            <Route path=":categoryName/:categoryID/:productName/:productId" element={<Product />} />
            <Route path="refer-earn" element={<ReferEarn />} />
            <Route path="our-location" element={<OurLocation />} />
            <Route path="category/:name" element={<ProductByCategory />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="search" element={<Search />} />
            <Route path={"order/:id"} element={<Order />} />
            <Route path={"old-order/:id"} element={<OrderOld />} />

            <Route path="user" element={<User />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="my-orders" element={<MyOrders />} />
              <Route path="my-account" element={<MyAccount />} />
              <Route path="update-profile" element={<UpdateProfile />} />
              <Route path="add-shipping-address" element={<AddShippingAddress />} />
              <Route path="change-password" element={<ChangePassword />} />
            </Route>

          </Route>

          {/* Blank Layout for Blog and Portfolio Pages */}
          <Route element={<BlankLayout />}>
            <Route path="blog" element={<Blog />} />
            <Route path="blog/:id" element={<BlogPost />} />
            <Route path="portfolio/:name/:id" element={<PortFolioPage />} />
          </Route>

          {/* Auth Layout for Login and Signup Pages */}
          <Route element={<AuthLayout isOpenRegister={isOpenRegister} setIsOpenRegister={setIsOpenRegister} />}>
            <Route path="auth/login" element={<LoginPage />} />
            <Route path="auth/signup" element={<SignupPage />} />
          </Route>

          {/* Fallback for 404 Page */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </Fragment>
  );
}

export default App;