import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  discountAmount: 0, // Added discountAmount to track the applied discount
  discountPercentage : 0,
};

export const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const productIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (productIndex >= 0) {
        state.cartItems[productIndex].cartQuantity += 1;
      } else {
        const tempProduct = { ...action.payload, cartQuantity: 1 };
        state.cartItems.push(tempProduct);
      }
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    removeFromCart(state, action) {
      state.cartItems = state.cartItems.filter(
        (cartItem) => cartItem.id !== action.payload.id
      );
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;
      }
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    incrementCart(state, action) {
      const productIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (productIndex >= 0) {
        state.cartItems[productIndex].cartQuantity += 1;
      } else {
        const tempProduct = { ...action.payload, cartQuantity: 1 };
        state.cartItems.push(tempProduct);
      }
      state.discountAmount = (state.cartTotalAmount *state.discountPercentage)/100
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    addByIncrement(state, action) {
      const productIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.product.id
      );
      if (productIndex >= 0) {
        state.cartItems[productIndex].cartQuantity += action.payload.cartQuantity;
      } else {
        const tempProduct = {
          ...action.payload.product,
          cartQuantity: action.payload.cartQuantity || 1,
        };
        state.cartItems.push(tempProduct);
      }
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    

    getTotals(state) {
      const { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { currentprice, cartQuantity } = cartItem;
          const itemTotal = currentprice * cartQuantity;

          cartTotal.total += itemTotal;
          cartTotal.quantity += cartQuantity;

          return cartTotal;
        },
        { total: 0, quantity: 0 }
      );

      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = parseFloat((total - state.discountAmount).toFixed(2));
    },

    applyDiscount(state, action) {
      state.discountAmount = action.payload; // Set the discount amount
    },
    setDiscountPercentage(state, action) {
      state.discountPercentage = action.payload; // Set the discount amount      
    },

    resetDiscount(state) {
      state.discountAmount = 0; // Reset the discount amount
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  decreaseCart,
  incrementCart,
  getTotals,
  addByIncrement,
  applyDiscount,
  resetDiscount,
  setDiscountPercentage,
} = CartSlice.actions;

export const cartReducer = CartSlice.reducer;
